<template>
  <TTView>
    <VRow>
      <VCol>
        <VBreadcrumbs
          class="pl-0"
          :items="breadcrumbs"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="12"
      >
        <h1 class="tt-text-headline-1">
          {{ procedure.name }}
        </h1>
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Компания
      </VCol>
      <VCol cols="9">
        {{ company.name }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Компетенция
      </VCol>
      <VCol cols="9">
        {{ competencyName }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Навык
      </VCol>
      <VCol cols="9">
        {{ skillName }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Дата начала
      </VCol>
      <VCol cols="9">
        {{ startAtDate }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Время начала
      </VCol>
      <VCol cols="9">
        {{ startAtTime }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Дата окончания
      </VCol>
      <VCol cols="9">
        {{ finishedAtDate }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Время окончания
      </VCol>
      <VCol cols="9">
        {{ finishedAtTime }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Куллдаун
      </VCol>
      <VCol cols="9">
        {{ cooldown }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Цикличность
      </VCol>
      <VCol cols="9">
        {{ continuous }}
      </VCol>
    </VRow>
    <VRow class="text-body-2">
      <VCol
        cols="3"
        class="tt-light-mono-64--text"
      >
        Описание процедуры
      </VCol>
      <VCol cols="9">
        {{ description }}
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div class="d-flex tt-flex-gap-2">
          <TTBtn
            :to="{ name : Names.R_LEARNING_ASSESSMENT_PROCEDURE_EDIT }"
          >
            Редактировать процедуру
          </TTBtn>

          <ConfirmableButton
            :max-width="350"
            :button-props="{
              class : 'ml-2'
            }"
            :to="{ name : Names.R_LEARNING_ASSESSMENT_PROCEDURE_ARCHIVE }"
            @confirm="handleClickProcedureArchive"
          >
            Архивировать процедуру
            <template #dialogContent>
              <p>
                Вы пытаетесь архивировать процедуру - это необратимый процесс.
                Фактически процедура будет удалена.
              </p>
              <p>Вы точно хотите архивировать процедуру?</p>
            </template>
            <template #confirmAction>
              Архивировать
            </template>
            <template #cancelAction>
              Отменить
            </template>
          </ConfirmableButton>
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VDivider />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <h2 class="tt-text-title-2">
          Шаги
        </h2>
      </VCol>
    </VRow>
    <template v-if="steps.length> 0">
      <VRow>
        <VCol>
          <TTDataTable
            :loading="loading"
            :headers="headers"
            :items="steps"
            @click:row="handleStepClick"
          />
        </VCol>
      </VRow>

      <VRow>
        <VCol>
          <TTBtn
            :to="{ name : Names.R_LEARNING_ASSESSMENT_STEP_CREATE }"
            color="tt-ghost"
            plain
          >
            <VIcon left>
              fal fa-plus
            </VIcon>

            Добавить шаг
          </TTBtn>
        </VCol>
      </VRow>

      <VRow v-if="totalPages>1">
        <VCol>
          <VPagination
            v-model="pagination.page"
            circle
            :disabled="loading"
            :length="totalPages"
            :total-visible="pagination.limit"
          />
        </VCol>
      </VRow>
    </template>
    <template v-else-if="!loading">
      <VRow>
        <VCol
          align="center"
          justify="center"
        >
          <VImg
            width="102"
            :src="require('@/assets/male-mexican-hat.png')"
          />

          <div>
            Нет шагов
          </div>

          <RouterLink :to="{ name : Names.R_LEARNING_ASSESSMENT_STEP_CREATE }">
            Создать шаг
          </RouterLink>
        </VCol>
      </VRow>
    </template>
  </TTView>
</template>

<script>
import ConfirmableButton from '../../components/ui/ConfirmableButton';

export default {
  name: 'ProcedureView',
  components: {
    ConfirmableButton,
  },
  inject: ['Names'],
  props: {
    accountId: { type: String, required: true },
    companyId: { type: String, required: true },
    groupId: { type: String, required: true },
    procedureId: { type: String, required: true },
  },
  data() {
    return {
      procedure: {},
      steps: [],
      competency: {},
      skill: {},
      company: {},
      account: {},
      group: {},
      headers: [
        { text: 'Название шага', value: 'name', sortable: false },
        { text: 'Описание', value: 'description', sortable: false },
      ],
      pagination: {
        page: 1,
        limit: 5,
        total: 0,
      },
    };
  },

  computed: {
    breadcrumbs() {
      const breadcrumbs = [
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.account.name },
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.companyName },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_GROUPS }, exact: true, text: 'Группы оценки' },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_GROUPS_VIEW }, exact: true, text: this.groupName },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_PROCEDURE_VIEW }, exact: true, text: this.procedureName },
      ];

      return breadcrumbs;
    },
    companyName() {
      return this.company?.name ? this.company.name : `Компания: ${this.companyId}`;
    },
    groupName() {
      return this.group?.name ? this.group.name : '-';
    },
    procedureName() {
      return this.procedure?.name ? this.procedure.name : `Процедура: ${this.groupId}`;
    },
    cooldown() {
      return this.procedure.cooldown > 0 ? this.procedure.cooldown : 'Не задан';
    },
    description() {
      return this.procedure.description ? this.procedure.description : 'Не задано';
    },
    continuous() {
      return this.procedure.continuous ? 'Задана' : 'Не задана';
    },
    startAtDate() {
      return this.procedure.startAt ? this.$dayjs(this.procedure.startAt).format('DD.MM.YYYY') : 'Не задана';
    },
    startAtTime() {
      return this.procedure.startAt ? this.$dayjs(this.procedure.startAt).format('HH:mm') : 'Не задано';
    },
    finishedAtDate() {
      return this.procedure.finishedAt ? this.$dayjs(this.procedure.finishedAt).format('DD.MM.YYYY') : 'Не задана';
    },
    finishedAtTime() {
      return this.procedure.finishedAt ? this.$dayjs(this.procedure.finishedAt).format('HH:mm') : 'Не задано';
    },
    competencyName() {
      return this.competency?.name ? this.competency.name : this.procedure?.competencyId;
    },
    skillName() {
      return this.skill?.name ? this.skill.name : this.procedure?.skillId;
    },
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  watch: {
    procedureId: {
      handler: 'fetch', immediate: true,
    },
    'pagination.page': async function handler() {
      await this.fetchSteps();
    },
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;
        const assessmentApp = new this.$di.ddd.Assessment();
        const accountsApp = new this.$di.ddd.Accounts();
        const { groupId, companyId, accountId } = this;

        const [[company], [group], [account]] = await Promise.all([
          await accountsApp.services.account.getCompany({ id: companyId }),
          await assessmentApp.services.assessment.fetchGroup({ groupId }),
          await accountsApp.services.account.getAccount({ id: accountId }),
        ]);

        this.account = account;
        this.company = { ...company };
        this.group = { ...group };

        const [procedure] = await assessmentApp.services.assessment.getProcedure(
          { id: this.procedureId, groupId: this.groupId },
        );
        this.procedure = { ...procedure };

        const [{ competency }, { skill }] = await Promise.all([
          await this.$di.api.CompetencyMatrix.CompetenciesGet({ id: this.procedure.competencyId }),
          await this.$di.api.CompetencyMatrix.SkillsGet({ id: this.procedure.skillId }),
        ]);
        await this.fetchSteps();

        this.competency = { ...competency };
        this.skill = { ...skill };
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async fetchSteps() {
      try {
        this.loading = true;
        const { procedureId } = this;
        const app = new this.$di.ddd.Assessment();
        const [{ steps, pagination }] = await app.services.assessment.fetchProcedureSteps({
          procedureId,
          ...this.pagination,
        });
        this.steps = steps;
        this.pagination = {
          page: Number(pagination.page),
          limit: Number(pagination.limit),
          total: Number(pagination.total),
        };
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    handleStepClick(item) {
      this.$router.push({
        name: this.Names.R_LEARNING_ASSESSMENT_STEP_VIEW,
        params: {
          stepId: item.id,
        },
      });
    },
    async handleClickProcedureArchive() {
      const { procedure, groupId } = this;

      const app = new this.$di.ddd.Assessment();
      const [isSuccess, error] = await app.services.assessment.archiveProcedure(procedure, groupId);

      if (isSuccess) {
        this.$router.push({
          name: this.Names.R_LEARNING_ASSESSMENT_GROUPS_VIEW,
          params: {
            groupId,
          },
        });
        this.$di.notify.snackSuccess(`Архивация процедуры с идентификатором ${procedure.id} выполнена успешно`);
      } else {
        this.$di.notify.errorHandler(`Архивация процедуры с идентификатором ${procedure.id} не выполнена: ${error}`);
      }
    },
  },
};

</script>
