<template>
  <VDialog
    v-model="dialog"
    :max-width="maxWidth"
  >
    <template #activator="{ on, attrs }">
      <TTBtn
        color="error"
        v-bind="{ ...attrs, ...buttonProps }"
        v-on="on"
      >
        <slot />
      </TTBtn>
    </template>

    <VCard>
      <VCardTitle
        v-if="hasTitle"
      >
        <slot
          name="dialogTitle"
        />
      </VCardTitle>
      <VCardText
        v-if="hasContent"
        class="px-6 py-4"
      >
        <slot
          name="dialogContent"
        />
      </VCardText>
      <VDivider />
      <VCardActions>
        <VSpacer />
        <TTBtn
          color="tt-secondary-danger"
          @click="cancel"
        >
          <slot name="cancelAction">
            Отменить
          </slot>
        </TTBtn>
        <TTBtn
          color="tt-danger"
          @click="confirm"
        >
          <slot name="confirmAction">
            Подтвердить
          </slot>
        </TTBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: 'ConfirmableButton',
  props: {
    buttonProps: {
      type: Object,
      default: () => ({}),
    },
    maxWidth: {
      type: Number,
      default: 350,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    hasTitle() {
      return this.$slots.dialogTitle;
    },
    hasContent() {
      return this.$slots.dialogContent;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    confirm() {
      this.closeDialog();
      this.$emit('confirm');
    },
    cancel() {
      this.closeDialog();
      this.$emit('cancel');
    },
  },
};
</script>
